import { toast } from "react-toastify";
import Api from '../../../api/Api';
import {GenericResultApi} from '../../../api/GenericResultApi';

interface SignupRequest {
    NomeCompleto: string
    Email: string
    Password: string
    RepeatPassword: string
}

class RegistrarApi {
    registrar = (data: SignupRequest, result: GenericResultApi<undefined>) => {
        if (data.NomeCompleto !== '' && data.Email !== '' && data.Password && data.RepeatPassword) {
            Api.post('/Auth/SignUp', data)
                .then(() => {
                    result.result(undefined);
                    toast.success('Registrado(a) com sucesso!')
                })
                .catch(err => {
                    result.error();
                    console.log(err)
                })
        } else {
            toast.error("Todos os campos precisam ser preenchidos.")
            result.error();
        }
    }
}

export default new RegistrarApi();