import React, { useState } from 'react';
import { useHistory } from 'react-router';
import logo from '../../../assets/img/Comuni logo white.png';
import Botao from '../../../components/Botao/Index';
import Input from '../../../components/Input/Index';
import RegistrarApi from './RegistrarApi';
const Registrar: React.FC = () => {
    const [nome, setNome] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [senha, setSenha] = useState<string>('');
    const [confirmarSenha, setConfirmarSenha] = useState<string>('');
    const [load, setLoad] = useState<boolean>(false);
    const history = useHistory();
    function registrar() {
        setLoad(true);
        RegistrarApi.registrar({
            Email: email,
            NomeCompleto: nome,
            Password: senha,
            RepeatPassword: confirmarSenha
        }, {
            result: () => {
                history.replace('/login')
                setLoad(false);
            },
            error: () => {
                setLoad(false);
            }
        })
    }

    return (
        <div className="bg_auth">
            <div className="d-flex align-items-center">
                <div className="vh-100 col-12 col-md-6 offset-0 offset-md-3 d-flex justify-content-center align-items-center">
                    <div className="w-100 text-center">
                        <img src={logo} alt="Comuni" width={320} />
                        <Input
                            title="Nome"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            tema="dark"
                        />
                        <Input
                            title="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            tema="dark"
                        />
                        <Input
                            title="Senha"
                            value={senha}
                            onChange={(e) => setSenha(e.target.value)}
                            type="password"
                            tema="dark"
                        />
                        <Input
                            title="Confirmar Senha"
                            value={confirmarSenha}
                            onChange={(e) => setConfirmarSenha(e.target.value)}
                            type="password"
                            tema="dark"
                        />
                        <div className="d-flex justify-content-between">
                            <Botao
                                title="Voltar"
                                onClick={() => history.replace('/login')}
                                outline
                                tema="Secondary"
                            />
                            <Botao
                                title="Registrar"
                                onClick={() => registrar()}
                                tema="Primary"
                                loading={load}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Registrar;