import React, { useEffect, useState } from 'react';
import Input from '../../../components/Input/Index';
import Botao from '../../../components/Botao/Index';
import logo from '../../../assets/img/Comuni logo white.png';
import { useHistory, useLocation } from 'react-router';
import EsqueciSenhaApi from './EsqueciSenhaApi';

type state = {
    email: string
}

const RedefinirSenha: React.FC = () => {
    const [codigo, setCodigo] = useState<string>("");
    const [novaSenha, setNovaSenha] = useState<string>("");
    const [confirmarSenha, setConfirmarSenha] = useState<string>("");
    const [load, setLoad] = useState<boolean>(false);
    const { state } = useLocation<state>();
    const history = useHistory();

    useEffect(() => {
        if (!state.email) history.goBack();
    }, [state.email, history])

    function redefinir() {
        setLoad(true);
        EsqueciSenhaApi.redefinirSenha({
            Code: parseInt(codigo),
            Email: state.email,
            Password: novaSenha,
            RepeatPassword: confirmarSenha
        }, {
            result: () => {
                setLoad(false);
                history.replace('/login')
            },
            error: () => {
                setLoad(false);
            }
        })
    }

    return (
        <div className="bg_auth">
            <div className="d-flex align-items-center">
                <div className="vh-100 col-12 col-md-6 offset-0 offset-md-3 d-flex justify-content-center align-items-center">
                    <div className="w-100 text-center">
                        <img src={logo} alt="Comuni" width={320} />
                        <Input
                            title="Email"
                            value={state.email}
                            disable
                            onChange={(e) => { }}
                            tema="dark"
                        />
                        <Input
                            title="Código"
                            value={codigo}
                            type="number"
                            onChange={(e) => setCodigo(e.target.value)}
                            tema="dark"
                        />
                        <Input
                            title="Nova senha"
                            value={novaSenha}
                            onChange={(e) => setNovaSenha(e.target.value)}
                            tema="dark"
                        />
                        <Input
                            title="Confirmar senha"
                            value={confirmarSenha}
                            onChange={(e) => setConfirmarSenha(e.target.value)}
                            tema="dark"
                        />
                        <div className="d-flex justify-content-between">
                            <Botao
                                title="Voltar"
                                onClick={() => history.push('/esqueciSenha')}
                                outline
                                tema="Secondary"
                            />
                            <Botao
                                title="Redefinir"
                                onClick={() => redefinir()}
                                tema="Primary"
                                loading={load}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RedefinirSenha;