import React, { useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { CardDescription, CardItem, CardTitle, CardItemOption, Badge } from './Style';
import PrincipalApi, { eventoGrupoItem } from '../../pages/Main/Principal/PrincipalApi';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import Modal from '../Modal/Index';
import Botao from '../Botao/Index';
import Input from '../Input/Index';
import InputTextarea from '../Input/InputTextarea';

const EventoItem: React.FC<eventoGrupoItem> = (prop) => {
    const [open, setOpen] = useState<boolean>(false);
    const [openModalDeletar, setOpenModalDeletar] = useState<boolean>(false);
    const [openModalAlterar, setOpenModalAlterar] = useState<boolean>(false);
    const [load, setLoad] = useState<boolean>(false);
    const [loadEdit, setLoadEdit] = useState<boolean>(false);

    //Alterar evento grupo
    const [titleEdit, setTitleEdit] = useState<string>("");
    const [subTitleEdit, setSubTitleEdit] = useState<string>("");
    const [startEdit, setStartEdit] = useState<Date>(new Date());
    const [endEdit, setEndEdit] = useState<Date>(new Date());

    useEffect(() => {
        setTitleEdit(prop.title)
        setSubTitleEdit(prop.subTitle)
        setStartEdit(prop.start)
        setEndEdit(prop.end)
    }, [prop.id, prop.start, prop.end, prop.subTitle, prop.title])

    //Usuários

    function deletarEvento() {
        setLoad(true);
        PrincipalApi.removerEvento({
            eventoId: prop.id,
            groupId: prop.groupId
        }, {
            result: () => {
                setLoad(false);
            },
            error: () => {
                setLoad(false);
            }
        })
    }

    function alterarEvento() {
        setLoadEdit(true)
        PrincipalApi.alterarEventoGrupo({
            eventoId: prop.id,
            groupId: prop.groupId
        }, {
            title: titleEdit,
            subTitle: subTitleEdit,
            start: startEdit,
            end: endEdit,
            alert: true,
        }, {
            result: () => {
                setOpenModalAlterar(false);
                setLoadEdit(false);
            },
            error: () => {
                setLoadEdit(false);
            }
        })
    }

    return (
        <>
            <CardItem>
                <div>
                    <CardTitle>{prop.title}</CardTitle>
                    <CardDescription>{prop.subTitle}</CardDescription>
                    <p>{prop.groupName}</p>
                    <CardItemOption>
                        <CardItemOption onClick={() => setOpen(!open)}>
                            <BsThreeDotsVertical size={28} />
                        </CardItemOption>
                        <Dropdown.Menu show={open} placeholder="right"  >
                            <Dropdown.Item onClick={() => { setOpenModalDeletar(true); setOpen(false) }}>Deletar Evento</Dropdown.Item>
                            <Dropdown.Item onClick={() => { setOpenModalAlterar(true); setOpen(false) }}>Alterar Evento</Dropdown.Item>
                        </Dropdown.Menu>
                    </CardItemOption>
                </div>
                <div className="d-flex justify-content-end">
                    <Badge>
                        {moment(prop.start).format('YYYY/MM/DD HH:mm')}
                    </Badge>
                    <Badge>
                        {moment(prop.end).format('YYYY/MM/DD HH:mm')}
                    </Badge>
                </div>
            </CardItem >

            {/* Modal deletar evento grupo */}
            <Modal
                open={openModalDeletar}
                actions={
                    <div className="d-flex justify-content-between">
                        <Botao
                            onClick={() => setOpenModalDeletar(false)}
                            title="Cancelar"
                            outline
                            tema='Primary'
                        />
                        <Botao
                            onClick={() => deletarEvento()}
                            title="Deletar Evento"
                            tema='Primary'
                            loading={load}
                        />
                    </div>
                }
            >
                <p>
                    Tem certeza que deseja deletar esse evento?
                </p>

            </Modal>


            {/* Modal alterar evento grupo */}

            <Modal
                title="Alterar evento"
                open={openModalAlterar}
                actions={
                    <div className="d-flex justify-content-between">
                        <Botao
                            onClick={() => setOpenModalAlterar(false)}
                            title="Cancelar"
                            outline
                            tema='Primary'
                        />
                        <Botao
                            onClick={() => alterarEvento()}
                            title="Alterar Evento"
                            tema='Primary'
                            loading={loadEdit}
                        />
                    </div>
                }
            >
                <Input
                    title="Título"
                    value={titleEdit}
                    onChange={(e) => setTitleEdit(e.target.value)}
                />
                <InputTextarea
                    title="Notas"
                    value={subTitleEdit}
                    onChange={(e) => setSubTitleEdit(e.target.value)}
                    row={4}
                />

                <div className="d-flex">
                    <Input
                        disable
                        title="Início"
                        value={startEdit}
                        onChange={() => { }}
                        type="datetime-local"
                    />
                    <Input
                        disable
                        title="Fim"
                        value={endEdit}
                        onChange={() => { }}
                        type="datetime-local"
                    />
                </div>
            </Modal>
        </>
    );
}

export default EventoItem;