import React, { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IGrupoResponse } from './IItems';
import { CardDescription, CardItem, CardTitle, CardItemOption } from './Style';
import Modal from '../Modal/Index';
import Botao from '../Botao/Index';
import { Dropdown } from 'react-bootstrap';
import Input from '../Input/Index';
import PrincipalApi from '../../pages/Main/Principal/PrincipalApi';

const GrupoItem: React.FC<IGrupoResponse> = (prop) => {
    const [open, setOpen] = useState<boolean>(false);
    const [loadConvidar, setLoadConvidar] = useState<boolean>(false);
    const [openModalConvidar, setOpenModalConvidar] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");

    function convidar() {
        setLoadConvidar(true)
        PrincipalApi.convidarUsuario({
            groupId: prop.id,
            emailUser: email
        }, {
            result: () => { setLoadConvidar(false) },
            error: () => { setLoadConvidar(false) }
        })
    }

    return (
        <>
            <CardItem>
                <div>
                    <CardTitle>{prop.name}</CardTitle>
                    <CardDescription>{prop.description}</CardDescription>
                    <CardItemOption>
                        <CardItemOption onClick={() => setOpen(!open)}>
                            <BsThreeDotsVertical size={28} />
                        </CardItemOption>
                        <Dropdown.Menu show={open} placeholder="right"  >
                            <Dropdown.Item onClick={() => { setOpenModalConvidar(true); setOpen(false) }}>Convidar usuário</Dropdown.Item>
                        </Dropdown.Menu>
                    </CardItemOption>
                </div>
                {/* <div className="d-flex justify-content-end">
                <Badge>
                    {prop.membros}
                    <p>Membros</p>
                </Badge>
                <Badge>
                    {prop.eventos}
                    <p>Membros</p>
                </Badge>
            </div> */}
            </CardItem>
            <Modal
                title="Convidar usuários"
                open={openModalConvidar}
                actions={
                    <div className="d-flex justify-content-between">
                        <Botao
                            onClick={() => setOpenModalConvidar(false)}
                            title="Cancelar"
                            outline
                            tema='Primary'
                        />
                        <Botao
                            onClick={() => convidar()}
                            title="Convidar Usuário"
                            tema='Primary'
                            loading={loadConvidar}
                        />
                    </div>
                }
            >
                <h5>Digite o email do usuário que deseja convidar para o grupo <br /> {prop.name}</h5>
                <Input
                    title={``}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Modal>
        </>
    );
}

export default GrupoItem;