import { toast } from "react-toastify";
import Api from "../../../api/Api";
import { GenericResultApi } from "../../../api/GenericResultApi";

interface LoginRequest {
    Email: string
    Password: string
}

interface LoginResponse {
    data: {
        token: string
        exp: string
    }
}

class LoginApi {
    entrar(request: LoginRequest, response: GenericResultApi<LoginResponse>) {
        if (request.Email !== '' && request.Password !== '') {
            Api.post<LoginResponse>('/Auth/SignIn', request)
                .then((res) => {
                    // setCookie('@COMUNI_TOKEN', res.data.data.token);
                    // setCookie('@COMUNI_EXP', res.data.data.exp);
                    localStorage.setItem('@COMUNI_TOKEN', res.data.data.token);
                    localStorage.setItem('@COMUNI_EXP', res.data.data.exp);
                    Api.defaults.headers.common["Authentication"] = res.data.data.token;
                    response.result(res.data);
                })
                .catch(err => {
                    response.error();
                    console.log({ '/Auth/logar(catch)': err })
                    if(err.response.data.error === "INVALID_EMAIL_PASSWORD") {
                        toast.error('Email ou senha inválidos.')
                    }
                })
        } else {
            toast.error('Preenche todos os campos.')
            response.error();
        }
    }
}

export default new LoginApi();