import moment from "moment";

export function isDebugCookie() {
    if (window.location.host.includes("comuni.henrystore")) {
        return 'comuni.henrystore';
    } else {
        return 'localhost';
    }
}

export function setCookie(name: string, value: string | null) {
    let s = isDebugCookie();
    console.log({ s })
    const stringResult = `${name}=${value};domain=${s};expires=${moment().add(20, 'hours')}`;

    document.cookie = stringResult
}

export function getCookie(cname: string): string {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
