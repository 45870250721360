import { toast } from 'react-toastify';
import Api from '../../../api/Api';
import { GenericResultApi } from '../../../api/GenericResultApi';

export interface eventoGrupoItem {
    id: string
    groupId: string
    title: string
    subTitle: string
    start: Date
    end: Date
    alert: boolean
    groupName: string
}

export interface eventoGrupoResponse {
    data: eventoGrupoItem[]
}

interface criarEventoRequest {
    title: string
    subTitle: string
    start: Date | string
    end: Date | string
    alert: boolean
}

interface deletarEventoRequest {
    eventoId: string
    groupId: string
}

type users = {
    email: string
}

export interface ListUsersResponse {
    data: users[]
}

interface ConvidarUsuarioRequest {
    emailUser: string
    groupId: string
}

interface alterarEventoGrupoRequest {
    title: string
    subTitle: string
    start: Date
    end: Date
    alert: boolean
}

class PrincipalApi {
    listarEventos(request: { groupID: string }, result: GenericResultApi<eventoGrupoResponse>) {
        Api.get<eventoGrupoResponse>(`/EventGroup/All/${request.groupID}`)
            .then(res => {
                result.result(res.data);
            })
            .catch(err => {
                console.log({ [`/EventGroup/All/${request.groupID}`]: err.response })
                result.error();
            })
    }

    criarEvento(id: { groupId: string }, request: criarEventoRequest, result: GenericResultApi<null>) {
        console.log({ [`/EventGroup/${id.groupId}`]: request })
        Api.post(`/EventGroup/${id.groupId}`, request)
            .then(res => {
                toast.success("Evento criado com sucesso.")
                result.result(null);
            })
            .catch(err => {
                console.log({ [`/EventGroup/${id.groupId}`]: err.response })
                result.error();
            })
    }


    removerEvento(data: deletarEventoRequest, result: GenericResultApi<null>) {
        Api.post(`/EventGroup/Delete/${data.eventoId}/${data.groupId}`)
            .then(() => {
                toast.success("Evento deletado com sucesso!")
                window.location.reload();
                result.result(null);
            })
            .catch(err => {
                result.error();
                console.log({ [`/EventGroup/${data.eventoId}/${data.groupId}`]: err.response })
            })
    }

    alterarEventoGrupo(data: deletarEventoRequest, request: alterarEventoGrupoRequest, result: GenericResultApi<null>) {
        Api.post<null>(`/EventGroup/Put/${data.eventoId}/${data.groupId}`, request)
            .then(() => {
                result.result(null);
                toast.success("Evento alterado com sucesso!");
                window.location.reload();
            })
            .catch(err => {
                console.log({ [`/EventGroup/Put/${data.eventoId}/${data.groupId}`]: err.response })
                result.error();
            })
    }

    listUsers(result: GenericResultApi<ListUsersResponse>) {
        Api.get<ListUsersResponse>('/User/List/All')
            .then(res => {
                result.result(res.data);
            })
            .catch(err => {
                console.log({ '/User/List/All': err.response })
                result.error();
            })
    }

    convidarUsuario(request: ConvidarUsuarioRequest, result: GenericResultApi<null>) {
        console.log({ '/UserGroup/Link(request)': request })
        Api.post('/UserGroup/Link', request)
            .then(res => {
                toast.success("Usuário Convidado com sucesso!")
                result.result(null);
            })
            .catch(err => {
                console.log({ '/UserGroup/Link': err.response })
                result.error()
                if (err.response.data.error === 'USER_NOT_FOUND')
                    toast.error("Usuário não encontrado.");

                if (err.response.data.error === 'ALREADY_INVITED_USER')
                    toast.warning("Usuário já está nesse grupo")

            })
    }

}

export default new PrincipalApi();