import styled from 'styled-components';
import bg from '../../assets/img/bg-principal.png';

export const BG = styled.div`
    width: 100%;
    height: 100vh;
    background: url(${bg});
    background-position: bottom right;
    background-repeat: no-repeat;
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 0;
`

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
`;
export const SidebarContainer = styled.div`
    height: 100vh;
    width: 280px;
    background: ${p => p.theme.colors.dark};
    padding: 20px;
    position: fixed;
    left: 0;
    bottom: 0;

    @media (max-width: 762px) { 
        bottom: 0;
        width:100vw;
        height: 90px;
        display: flex;
        justify-content: space-around;
        z-index: 2;
    }
`;

export const SideBarLogo = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    @media (max-width: 762px) { 
        display: none;
    }
`

export const SideBarItem = styled.button`
    display: flex;
    color: #fff;
    width: 100%;
    padding: 20px;
    background: transparent;
    border: transparent !important;
    outline-color: transparent !important;
    justify-content: start;
    align-items: center;
    p {
        margin: 0 !important;
        padding-left: 12px;
    }


    @media (max-width: 762px) { 
        justify-content: center;
        p {
            display: none;
        }
    }
`;

export const Main = styled.div`
     padding-left: 280px;
     width: 100%;
     height: 100vh;
     overflow: hidden;
     
    @media (max-width: 762px) { 
        padding-left: 0 !important;
        .main_space {
            padding-bottom: 20px;
            display: inline-block;
        }
    }
`;

