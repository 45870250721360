import React from 'react';
import { InputTextareaType } from './IInput';
import { InputContainer, InputLabel, InputInputTextarea } from './Style';


const InputTextarea: React.FC<InputTextareaType> = (prop) => {
    return (
        <InputContainer>
            <InputLabel>{prop.title}</InputLabel>
            <InputInputTextarea value={prop.value} onChange={prop.onChange} />
        </InputContainer>
    );
}

export default InputTextarea;