import React from 'react';
import { ModalStyle, ModalCard, ModalText, ModalContext, ModalAction } from './Style';

export type ModalType = {
    open: boolean
    title?: string
    actions?: React.ReactNode
}

const Modal: React.FC<ModalType> = ({
    open,
    title,
    actions,
    children,
}) => {
    return (
        <ModalStyle open={open}>
            <ModalCard>
                <ModalText>{title}</ModalText>
                <ModalContext>
                    {children}
                </ModalContext>
                <ModalAction>
                    {actions}
                </ModalAction>
            </ModalCard>
        </ModalStyle>
    );
}

export default Modal;