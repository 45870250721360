import styled, { css } from 'styled-components';

type MobileBarType = {
    isActive: boolean
}

export const NavbarContainer = styled.div<MobileBarType>`
    display: flex;
    position: fixed;
    background: #fff6;
    border-radius: 0 0 20px 20px;
    z-index: 2;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    justify-content: space-between;
    align-items:center;
    width: 100%;
    height: 72px;
    ul {
        display: flex;
       li {
           list-style: none !important;

           a {
               height: 72px;
               display: table-cell;
               vertical-align: middle;
               color: ${p => p.theme.colors.dark};
               padding: 0 12px;
               text-decoration: none !important;

            &.bold {
                font-weight: bold !important;
            }
           }
       }
    }

    @media(max-width: 782px) {
        ul {
            flex-direction: column;
            padding-left: 0 !important;
            width: 100%;
            position: fixed;
            left: 0;
            top: 0;
            transition: .4s ease all !important;
            ${p => !p.isActive && css`
                top: 100vh !important;
            `}
            justify-content: center;
            height: 100vh;
            background: ${p => p.theme.colors.yellow};
            li {
                justify-content: center;
                display: flex;
                height: auto;
                padding-bottom: 20px;
            }
        }
    }
`;

export const MobileBar = styled.button<MobileBarType>`
    width: 80px;
    height: 80px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 100%;
    position: fixed;
    left: 50%;
    
    background: ${p => p.theme.colors.yellow};
    border: none;
    outline-color: transparent !important;
    z-index: 8;
    transition: .4s ease all !important;
    ${p => p.isActive && css`
        bottom: 42px;
        transform:  translateX(-50%) rotate(0deg);
    `}

    ${p => !p.isActive && css`
        bottom: -32px;
        transform:  translateX(-50%) rotate(180deg);
    `}
`;
