import React from 'react';
import { InputType } from './IInput';
import { InputContainer, InputInput, InputLabel } from './Style';

const Input: React.FC<InputType> = ({
    onChange,
    tema = 'light',
    title,
    type,
    disable,
    value
}) => {
    return (
        <InputContainer>
            <InputLabel tema={tema}>{title}</InputLabel>
            <InputInput type={type} tema={tema} disable={disable} disabled={disable} value={value} onChange={onChange} />
        </InputContainer>
    );
}

export default Input;