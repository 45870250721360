import styled from 'styled-components';

export const CardItem = styled.div`
    background: #ebebeb;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    margin-bottom: 16px;
`;
export const CardTitle = styled.p`
    font-size: 1.4rem;
    font-weight: medium;
`;
export const CardDescription = styled.div`
    font-size: .8rem;
`;
export const Badge = styled.div`
    display: flex;
    color: #fff;
    align-items:center;
    background: ${p => p.theme.colors.red};
    font-size: 12px;
    margin: 4px;
    padding: 8px 12px;
    border-radius: 999px;

    p {margin-left: 12px}
`;

export const CardItemOption = styled.button`
    position: absolute;
    right: 20px;
    top: 12px;
    background: transparent;
    border: none;
`;