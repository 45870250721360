import { createGlobalStyle } from 'styled-components';

export const GloblalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: "Roboto", sans-serif;
    }

    h1,h2,h3, h4, h5, strong {
        font-weight: bold;
    }

    p {
        margin: 0;
    }

    .card_blur {
        background: #ffffff;
        width: 100%;
        height: 100%;
        z-index: -1;
        backdrop-filter: blur(12px) opacity(18%) ;
        mix-blend-mode: multiply;
        border-radius: 20px;
            padding: 20px;
            border-radius: 20px;
        }

        .card_blur_before {
        position: relative;
        &:before {
            content: '';
            background: #ffffff;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            backdrop-filter: blur(12px) opacity(18%) ;
            mix-blend-mode: multiply;
            border-radius: 20px;
        }            
            border-radius: 20px;
        }


    .text_big {
        font-size: 1.6rem !important;
    }

    .cursor-pointer {
        cursor: pointer !important;
    }

    .no_link {
        text-decoration: none !important;
    }

    .flex_direction_column {
        flex-direction: column;
        a {
            display: block;
        }
    }

    .bg_auth {
        background: linear-gradient(45deg, #000, #222 );
        width: 100%;
        height: 100vh;
    }

    .line_button {
        display: flex;
        align-items:center;
        width: 100%;
        background: transparent !important;
        outline: none !important;
        outline-color: transparent;
        border: none !important;
        padding-top: 8px;
        padding-bottom: 8px;
        hr,p {
            color: #fff;
            width: 100%;
        }
    }

    .card_transparent {
        background: #fff5;
        padding: 20px;
        border-radius:20px;
    }

    .card {
        padding: 20px;
        border-radius:20px;
    }
`;
