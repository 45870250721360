import styled, { css } from 'styled-components';
import { InputStyle } from './IInput';

export const InputContainer = styled.div`
  margin-bottom: 12px;
  margin: 4px;
`;

export const InputInput = styled.input<InputStyle>`
    padding: 12px 12px;
    border-radius: 18px;
    width: 100%;
    outline: none !important;
    border: none !important;    
    box-shadow: 0px 2px 12px #0002;
    ${p => p.disable && css`
        &:disabled {
            background: #fffb;
            color: #0005;
        }
    `}
`;


export const InputLabel = styled.p<InputStyle>`
    text-align: left !important;
    ${p => p.tema === 'light' && css`
        color: ${p.theme.colors.dark};
    `}
    ${p => p.tema === 'dark' && css`
        color: #fff;
    `}  
`

export const InputInputTextarea = styled.textarea`
    padding: 12px 12px;
    border-radius: 18px;
    width: 100%;
    outline: none !important;
    border: none !important;    
    box-shadow: 0px 2px 12px #0002;
    resize: none;
`;