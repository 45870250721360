import { toast } from 'react-toastify';
import Api from '../../../api/Api';
import { GenericResultApi } from '../../../api/GenericResultApi';

interface EsqueciSenhaRequest {
    Email: string
}

interface RedefinirSenhaRequest {
    Code: number
    Password: string
    RepeatPassword: string
    Email: string
}


class EsqueciSenhaApi {
    codigo = (data: EsqueciSenhaRequest, result: GenericResultApi<undefined>) => {
        if (data.Email !== "") {
            console.log({
                '/Auth/ForgotPassword(request)': data
            })
            Api.post('/Auth/ForgotPassword', data)
                .then((res) => {
                    result.result(undefined);
                })
                .catch(err => {
                    console.log({ 'catch': err.response })
                    result.error();
                    if(err.response.data.error === 'INVALID_EMAIL') {
                        toast.error('Email inválido.')
                    }
                })
        } else {
            toast.error('Preenche o campo com o seu email.')
            result.error();
        }
    }


    redefinirSenha = (data: RedefinirSenhaRequest, result: GenericResultApi<undefined>) => {
        console.log({
            "Auth/RedefinePassword(request)": data
        })
        if (data.Code !== 0 && data.RepeatPassword !== "" && data.Password !== "") {
            if (data.Password === data.RepeatPassword) {
                Api.post('Auth/RedefinePassword', data)
                    .then(() => {
                        result.result(undefined);
                        toast.success("Senha redefinida com sucesso!")
                    }).catch(err => {
                        console.log(err.response.data);
                        if (err.response.data.error === "INVALID_CODE")
                            toast.error("Código inválido")
                        result.error();
                    })
            } else {
                toast.error('nova senha e confirmar senha não coincidem.')
                result.error();
            }
        } else {
            toast.error('Preenche todos os campos.')
            result.error();
        }
    }

}

export default new EsqueciSenhaApi();