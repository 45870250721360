import React, { useState } from 'react';
import Botao from '../components/Botao/Index';
import Navbar from '../components/Navbar/Index';
import list from '../assets/img/list-calendar.png';
import { LPContainer } from '../style/PagesStyle/LP.style';
import evento from '../assets/img/Evento.png';
import grupoImage from '../assets/img/image-grupos.png';
import Input from '../components/Input/Index';
import logoWhite from '../assets/img/Comuni logo white.png';
import { Link } from 'react-scroll';

import agendamento from '../assets/img/lp_agendamento.png';
import usablildade from '../assets/img/lp_usabilidade.png';
import compartilhar from '../assets/img/lp_compartilhar.png';

const LP: React.FC = () => {
    const [nome, setNome] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [mensagem, setMensagem] = useState<string>("");
    return (
        <>
            <Navbar />
            <LPContainer>
                <section id="Principal" className="vh-100 d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 text-md-start text-center pb-4 pb-md-0">
                                <h1>
                                    Agende e compartilhe
                                    seus compromissos
                                </h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper integer integer.
                                </p>

                                <Botao
                                    title="Disponível na play store"
                                    onClick={() => { }}
                                    tema="Primary"
                                />
                            </div>
                            <div className="col-md-7 position-relative">
                                <div className='card_blur'>
                                    <img src={list} alt="lista" className="w-100" />
                                </div>
                                <img src={evento} alt="evento" className="evento d-none d-md-flex" />
                            </div>
                        </div>
                        <div className="row pt-5 d-none d-md-flex">
                            <div className="col-3">
                                <img src={evento} alt="evento" className="w-100" />
                            </div>
                            <div className="col-3">
                                <img src={evento} alt="evento" className="w-100" />
                            </div>
                            <div className="col-3">
                                <img src={evento} alt="evento" className="w-100" />
                            </div>
                            <div className="col-3">
                                <img src={evento} alt="evento" className="w-100" />
                            </div>
                        </div>
                    </div>
                </section>

                <section id="Agendamento">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <h2>Agende e compartilhe seus compromissos</h2>
                                <p className="mb-5">
                                    Além de criar eventos para o uso pessoal, você pode compartilhar para qualquer grupo.
                                </p>

                                <div className="row">
                                    <div className="col-6 mb-4">
                                        <div className="d-flex mb-3">
                                            <img src={usablildade} alt="" className="img_agenda" />
                                            <div>
                                                <strong>Fácil usabilidade</strong>
                                                <p>Com alguns toques é possível criar eventos.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-4">
                                        <div className="d-flex mb-3">
                                            <img src={agendamento} alt="" className="img_agenda" />
                                            <div>
                                                <strong>Interação com a agenda</strong>
                                                <p>Comuni tem acesso à agenda de seu celular para te alertar dos eventos.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 mb-4">
                                        <div className="d-flex mb-3">
                                            <img src={compartilhar} alt="" className="img_agenda" />
                                            <div>
                                                <strong>Compartilhando</strong>
                                                <p>Os eventos podem servir para o seu grupo, compartilhe-o com eles!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <section id="Grupo">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 order-1 order-md-0">
                                <img src={grupoImage} alt="grupos" className="w-100" />
                            </div>
                            <div className="col-md-6 col-12 d-flex align-items-center order-0 order-md-1">
                                <div className="text-md-end text-center">
                                    <strong className="small">Simplicidade</strong>
                                    <h2 >Grupos</h2>
                                    <p className="text_big">
                                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Explicabo voluptate commodi quod error ab placeat ipsa fuga cupiditate autem illo!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="position-relative">
                    <div className="donut_2"></div>

                    <section id="Contato">
                        <div className="container">
                            <div className="col-md-8 offset-md-2 col-12 offset-0 position-relative">
                                <div className="card_blur_before">
                                    <div className="p-4">
                                        <h2>Você tem tarefas hoje?</h2>
                                        <p>Caso tenha um tempo livre, comente o que acha sobre a Comuni.</p>
                                        <div className="row">
                                            <div className="col-6">
                                                <Input
                                                    value={nome}
                                                    title="Nome"
                                                    onChange={(e) => setNome(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <Input
                                                    value={email}
                                                    title="Email"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-12 pt-4">
                                                <Input
                                                    value={mensagem}
                                                    title="Mensagem"
                                                    onChange={(e) => setMensagem(e.target.value)}
                                                />
                                                <div className="text-center">
                                                    <Botao
                                                        onClick={() => { }}
                                                        title="Enviar"
                                                        tema="Primary"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>


                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-4 text-center text-md-start">
                                <img src={logoWhite} alt="white" width={180} className="pb-3" />
                                <p className="text-white text-big">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut justo lobortis ut.
                                </p>
                            </div>
                            <div className="col-12 col-md-4 text-center text-md-start">
                                <h3 className="text-white">Naveação</h3>
                                <div className="flex_direction_column">
                                    <Link
                                        to="Principal"
                                        activeClass="bold"
                                        className="cursor-pointer no_link text-white pb-2"
                                    >Principal
                                    </Link>
                                    <Link
                                        to="Agendamento"
                                        activeClass="bold"
                                        offset={-100}
                                        className="cursor-pointer no_link text-white pb-2"
                                    >Agendamento
                                    </Link>
                                    <Link
                                        to="Grupo"
                                        offset={160}
                                        activeClass="bold"
                                        className="cursor-pointer no_link text-white pb-2"
                                    >Grupo
                                    </Link>
                                </div>
                            </div>
                            <div className="col-4">
                            </div>

                            <small className="pt-5 pb-5 pb-md-0 text-white text-center text-md-start">
                                COMUNI 2021 © - Todos os direitos reservados.
                            </small>
                        </div>
                    </div>
                </footer>
            </LPContainer>
        </>
    );
}

export default LP;