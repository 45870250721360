import React, { useEffect, useState } from 'react';
import Botao from '../../../components/Botao/Index';
import Input from '../../../components/Input/Index';
import InputTextarea from '../../../components/Input/InputTextarea';
import { IGrupoResponse } from '../../../components/Items/IItems';
import GrupoApi from '../Grupos/GrupoApi';
import PrincipalApi, { eventoGrupoItem } from './PrincipalApi';
import EventoItem from '../../../components/Items/EventoItem';

const Principal: React.FC = () => {
  const [load, setLoad] = useState<boolean>(false);
  //criar evento
  const [nomeEvento, setNomeEvento] = useState<string>("")
  const [grupos, setGrupos] = useState<IGrupoResponse[]>([])
  useEffect(() => {
    GrupoApi.listAll({
      result: (e) => {
        setGrupos(e.data)
      },
      error: () => { }
    })
  }, [])
  const [selectedGrupo, setSelectedGrupo] = useState<string>("")
  const [filterGrupo, setFilterGrupo] = useState<string>("");
  const [inicioDate, setInicioDate] = useState<Date>(new Date())
  const [fimDate, setFimDate] = useState<Date>(new Date())
  const [descricao, setDescricao] = useState<string>("");

  //listar Eventos
  const [eventos, setEventos] = useState<eventoGrupoItem[]>([]);


  useEffect(() => {
    function getListEventos() {
      PrincipalApi.listarEventos({
        groupID: filterGrupo
      }, {
        result: (i) => {
          console.log({
            'eventinhos': i
          })
          setEventos(i.data);
        },
        error: () => { }
      })
    }
    getListEventos();
  }, [filterGrupo])



  function criarEvento() {
    setLoad(true);
    PrincipalApi.criarEvento({
      groupId: selectedGrupo
    }, {
      end: fimDate,
      start: inicioDate,
      subTitle: descricao,
      title: nomeEvento,
      alert: true
    }, {
      result: () => {
        setLoad(false);
        window.location.reload();
      },
      error: () => {
        setLoad(false);
      }
    })
  }

  return (
    <div className="row mt-4">
      <div className="col-7">
        <select className="w-100 mb-3" name="filterEvento" id="filterEvento" onChange={(e) => setFilterGrupo(e.target.value)}>
          <option value="" selected >Filtrando</option>
          {
            grupos.map(e => (
              <option value={e.id}>{e.name}</option>
            ))
          }
        </select>
        {
          eventos.map(e => (
            <EventoItem
              id={e.id}
              groupId={e.groupId}
              alert={e.alert}
              end={e.end}
              groupName={e.groupName}
              start={e.start}
              subTitle={e.subTitle}
              title={e.title}
            />
          ))
        }
      </div>
      <div className="col-5">
        <div className="card">
          <strong>Criar novo evento</strong>

          <Input
            title="Nome do Evento"
            value={nomeEvento}
            onChange={(e) => setNomeEvento(e.target.value)}
            tema="light"
          />

          <InputTextarea
            title="Descrição"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            row={3}
          />

          <div className="d-flex align-items-center justify-content-between py-3">
            <select name="Grupo" id="Grupo" onChange={(e) => setSelectedGrupo(e.target.value)}>
              <option value="" selected >Selecione grupo em que o evento pertence</option>
              {
                grupos.map(e => (
                  <option value={e.id}>{e.name}</option>
                ))
              }
            </select>
          </div>

          <Input
            title="Inicio"
            value={inicioDate}
            onChange={(e) => setInicioDate(e.target.value)}
            tema="light"
            type="datetime-local"
          />

          <Input
            title="Fim"
            value={fimDate}
            onChange={(e) => setFimDate(e.target.value)}
            tema="light"
            type="datetime-local"
          />

          <Botao
            title="Criar evento"
            onClick={() => criarEvento()}
            tema="Primary"
            loading={load}
          />
        </div>
      </div>
    </div>
  );
}

export default Principal;