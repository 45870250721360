import React, { useState } from 'react';
import { useHistory } from 'react-router';
import logo from '../../../assets/img/Comuni logo white.png';
import Botao from '../../../components/Botao/Index';
import Input from '../../../components/Input/Index';
import LoginApi from './LoginApi';

const Login: React.FC = () => {
    const history = useHistory();
    const [email, setEmail] = useState<string>('');
    const [senha, setSenha] = useState<string>('');
    const [load, setLoad] = useState<boolean>(false);
    function entrar() {
        setLoad(true);
        LoginApi.entrar({
            Email: email,
            Password: senha
        }, {
            result: (e) => {
                history.push('/principal')
                setLoad(false);
            },
            error: () => { setLoad(false) }
        })
    }

    return (
        <div className="bg_auth">
            <div className="d-flex align-items-center">
                <div className="vh-100 col-12 col-md-6 offset-0 offset-md-3 d-flex justify-content-center align-items-center">
                    <div className="w-100 text-center">
                        <img src={logo} alt="Comuni" width={320} />
                        <div>
                            <Input
                                title="Email"
                                onChange={(t) => setEmail(t.target.value)}
                                value={email}
                                tema="dark"
                            />
                            <Input
                                title="Senha"
                                onChange={(t) => setSenha(t.target.value)}
                                value={senha}
                                type="password"
                                tema="dark"
                            />

                            <button className="line_button" onClick={() => history.push('/esqueciSenha')}>
                                <hr />
                                <p>Esqueci senha</p>
                                <hr />
                            </button>
                            <div className="d-flex justify-content-between py-4">
                                <Botao
                                    title="Voltar"
                                    outline
                                    tema="Secondary"
                                    onClick={() => history.replace('/')}
                                />
                               <div className="d-flex">
                               <Botao
                                    title="Não sou cadastrado"
                                    tema="Dark"
                                    onClick={() => history.push('/registrar')}
                                />
                                 <Botao
                                    title="Entrar"
                                    tema="Primary"
                                    loading={load}
                                    onClick={() => entrar()}
                                />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;