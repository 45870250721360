import { toast } from 'react-toastify';
import Api from '../../../api/Api'
import { GenericResultApi } from '../../../api/GenericResultApi'
import { IGrupoResponse } from '../../../components/Items/IItems';

interface RegistrarGrupoRequest {
    nome: string
    descricao: string
}

class GrupoApi {

    listAll(result: GenericResultApi<{data: IGrupoResponse[]}>) {
       
        Api.get<{data: IGrupoResponse[]}>("/group/GetAll")
            .then(res => {
                console.log(res.data.data);
                result.result(res.data);
            })
            .catch(err => {
                result.error();
                console.log({ "/group/GetAll(catch)": err.response });
            })
    }

    registrarGrupo(request: RegistrarGrupoRequest, result: GenericResultApi<null>) {
        console.log({
            request
        })
        Api.post('/Group/Post', request)
            .then(res => {
                toast.success("Grupo criado com sucesso.")
                result.result(null);
            })
            .catch(err => {
                console.log({ '/Group/Post(catch)': err.response });
                result.error();
            })
    }

}
export default new GrupoApi();