import React, { useState } from 'react';
import Botao from '../Botao/Index';
import { MobileBar, NavbarContainer } from '../Style';
import logo from '../../assets/img/Comuni logo.png';
import { Link } from 'react-scroll';
import { MdClose } from 'react-icons/md'
import { FaBars } from 'react-icons/fa'
import { useHistory } from 'react-router';
const Navbar: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const history = useHistory();
    return (
        <>
            {
                window.innerWidth < 762 && (
                    <MobileBar isActive={open} onClick={() => setOpen(!open)}>
                        {open && <MdClose size={50} color="#fff" />}
                        {!open && <FaBars size={20} color="#fff" />}
                    </MobileBar>
                )
            }
            <NavbarContainer className="container " isActive={open}>
                <img src={logo} alt="Comuni" height={42} />

                <ul>
                    <li>
                        <Link
                            to="Principal"
                            activeClass="bold"
                            className="cursor-pointer"
                            onClick={() => setOpen(false)}
                        >
                            Principal
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="Agendamento"
                            activeClass="bold"
                            offset={-100}
                            className="cursor-pointer"
                            onClick={() => setOpen(false)}
                        >
                            Agendamento
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="Grupo"
                            activeClass="bold"
                            className="cursor-pointer"
                            offset={160}
                            onClick={() => setOpen(false)}
                        >
                            Grupo
                        </Link>
                    </li>
                    <li className="d-flex align-items-center">
                        <Botao
                            title="Entrar"
                            size="small"
                            onClick={() => history.push('/login')}
                        />
                    </li>
                </ul>
            </NavbarContainer>
        </>
    );
}

export default Navbar;