import React from 'react';
import { AiFillHome } from 'react-icons/ai';
import { MdExitToApp, MdGroup } from 'react-icons/md';
import { Main, SidebarContainer, SideBarItem, Wrapper, SideBarLogo, BG } from './Style';
import logo from '../../assets/img/Comuni logo white.png';
import { useHistory } from 'react-router';
import { setCookie } from '../../Services/CookieService';

const Sidebar: React.FC = ({ children }) => {
    const history = useHistory();


    function sair() {
        setCookie('@COMUNI_EXP', '');
        setCookie('@COMUNI_TOKEN', '')
        history.replace('/login');
    }

    return (
        <Wrapper>
            <SidebarContainer>
                <SideBarLogo>
                    <img src={logo} alt="Comuni" className="w-75" />
                </SideBarLogo>
                <SideBarItem onClick={() => history.push('/principal')}>
                    <AiFillHome size={20} />
                    <p>Principal</p>
                </SideBarItem>
                <SideBarItem onClick={() => history.push('/grupos')}>
                    <MdGroup size={20} />
                    <p>Grupos</p>
                </SideBarItem>
                <SideBarItem onClick={() => sair()}>
                    <MdExitToApp size={20} />
                    <p>Sair</p>
                </SideBarItem>
            </SidebarContainer>
            <Main>
                <div className="main_space">
                    <div className="container">
                        {children}
                    </div>
                </div>
                <BG />
            </Main>
        </Wrapper >
    );
}

export default Sidebar;