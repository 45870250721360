import styled, { css } from 'styled-components';
import { ModalType } from './Index';

export const ModalStyle = styled.div<ModalType>`
    ${props => !props.open && css`
        display: none !important;
    `};

    width: 100%;
    height: 100vh;
    background: #0003;
    position: fixed;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 4;
`;

export const ModalCard = styled.div`
    /* max-width: 80%; */
    padding: 20px;
    min-width: 480px;
    max-width: 640px;
    @media(max-width: 762px) {
        min-width: 100% !important;
        margin: 20px 0;
    }
    background: #fff;
    border-radius: 20px;
    z-index: 5;
`; 

export const ModalText = styled.p`
    font-size: 1.4rem;
    color: ${props => props.theme.colors.dark};
    padding-bottom: 20px;
`; 

export const ModalContext = styled.div`
    overflow-y: auto;
    width: 100%;
    max-height: 75vh;
`;

export const ModalAction = styled.div`
    border-top: solid 1px #f5f5f5;
    padding-top: 8px;
`;