import React from 'react';
import { IBotao } from './BotaoType';
import { BotaoContainer, Rotate } from './Style';
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

const Botao: React.FC<IBotao> = ({
    onClick,
    size = 'normal',
    outline,
    tema = 'Dark',
    title,
    loading
}) => {
    return (
        <BotaoContainer
            size={size}
            tema={tema}
            onClick={onClick}
            outline={outline}
            loading={loading}
            disabled={loading}
        >
            {
                loading === true && (
                    <Rotate>
                        <AiOutlineLoading3Quarters size={20} />
                    </Rotate>
                )
            }
            <p>{title}</p>
        </BotaoContainer>
    );
}

export default Botao;