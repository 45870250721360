import React, { useEffect, useState } from 'react';
import Botao from '../../../components/Botao/Index';
import { IGrupoResponse } from '../../../components/Items/IItems';
import GrupoItem from '../../../components/Items/GrupoItem';
import GrupoApi from './GrupoApi';
import Modal from '../../../components/Modal/Index';
import Input from '../../../components/Input/Index';
import InputTextarea from '../../../components/Input/InputTextarea';
const Grupos: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [nome, setNome] = useState<string>("");
    const [descricao, setDescricao] = useState<string>("");
    const [load, setLoad] = useState<boolean>(false);
    const [grupos, setGrupos] = useState<IGrupoResponse[]>([
        // {
        //     nome: "grupo #1",
        //     descricao: "olá mundo",
        //     eventos: 18,
        //     id: "23rsdfg576jh",
        //     membros: 9
        // }
    ]);
    useEffect(() => {
        GrupoApi.listAll({
            result: (e) => { setGrupos(e.data) },
            error: () => { }
        })
    }, [])


    function registrarGrupo() {
        setLoad(true);
        GrupoApi.registrarGrupo({
            descricao: descricao,
            nome: nome
        }, {
            result: () => {
                setLoad(false);
                setOpen(false);
                window.location.reload();
            },
            error: () => {
                setLoad(false);
            }
        })
    }

    return (
        <>
            <div className="card_transparent">
                <div className="d-flex justify-content-end mb-4">
                    <Botao
                        title="Criar novo grupo"
                        onClick={() => setOpen(true)}
                    />
                </div>

                <div className="row">
                    {
                        grupos.map(e => (
                            <div className="col-md-6 col-12 mb-md-0 mb-4">
                                <GrupoItem
                                    id={e.id}
                                    name={e.name}
                                    description={e.description}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
            <Modal
                open={open}
                title="Criar novo grupo"
                actions={
                    <div className="d-flex justify-content-between">
                        <Botao
                            onClick={() => setOpen(false)}
                            title="Cancelar"
                            outline
                        />
                        <Botao
                            onClick={() => registrarGrupo()}
                            title="Criar Grupo"
                            tema="Primary"
                            loading={load}
                        />
                    </div>
                }
            >
                <Input
                    title="Nome do Grupo"
                    onChange={(e) => setNome(e.target.value)}
                    value={nome}
                />

                <InputTextarea
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    title="Descrição do grupo"
                    row={3}
                />

            </Modal>
        </>
    );
}

export default Grupos;