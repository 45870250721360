import styled, { css } from 'styled-components';
import { BotaoStyleType } from './BotaoType';

export const BotaoContainer = styled.button<BotaoStyleType>`
    position: relative;
    display: flex;
    align-items:center;
    justify-content: center;
            ${p => p.tema === 'Primary' && css`
                    border: 1px solid ${p => p.theme.colors.red};
                    background: ${p => p.theme.colors.red};
                    color: #fff;
                    ${p.outline && css`
                        background: transparent !important ;
                        color: ${p => p.theme.colors.red} !important;
                    `}
            `}
            ${p => p.tema === 'Dark' && css`
                    border: 1px solid ${p => p.theme.colors.dark};
                    background: ${p => p.theme.colors.dark};
                    color: #fff;
                    ${p.outline && css`
                        background: transparent !important ;
                        color: ${p => p.theme.colors.dark} !important;
                    `}
            `}
            ${p => p.tema === 'Secondary' && css`
                    border: 1px solid ${p => p.theme.colors.yellow};
                    background: ${p => p.theme.colors.yellow};
                    color: #fff;
                    ${p.outline && css`
                        background: transparent !important ;
                        color: ${p => p.theme.colors.yellow} !important;
                    `}
            `}

        ${p => p.size === 'normal' && css`
            font-size: 1rem;
            padding: 12px 32px;
            margin: 4px;
            border-radius: .4rem;
        `}
        ${p => p.size === 'small' && css`
            font-size: .8rem;
            padding: 6px 22px;
            margin: 4px;
            border-radius: .25rem;
        `}
        ${p => p.size === 'big' && css`
            font-size: 1rem;
            padding: 12px 18px;
            margin: 4px;
            border-radius: .4rem;
        `}

        @keyframes rotate {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        ${p => p.loading && css`
            p {
                opacity: 0 !important;
            }
    `}
`;

export const Rotate = styled.div`
    position: absolute;
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    animation: rotate linear .8s infinite;
    display: flex;

`;
