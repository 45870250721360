import React, { useState } from 'react';
import Input from '../../../components/Input/Index';
import Botao from '../../../components/Botao/Index';
import logo from '../../../assets/img/Comuni logo white.png';
import { useHistory } from 'react-router';
import EsqueciSenhaApi from './EsqueciSenhaApi';

type state = {
    email: string
}

const EsqueciSenha: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [load, setLoad] = useState<boolean>(false);
    const history = useHistory<state>();

    function enviarCodigo() {
        setLoad(true);
        EsqueciSenhaApi.codigo({
            Email: email
        }, {
            result: () => {
                history.push('/redefinirSenha', {
                    email: email
                })
                setLoad(false);
            },
            error: () => {
                setLoad(false);
            }
        })
    }

    return (
        <div className="bg_auth">
            <div className="d-flex align-items-center">
                <div className="vh-100 col-12 col-md-6 offset-0 offset-md-3 d-flex justify-content-center align-items-center">
                    <div className="w-100 text-center">
                        <img src={logo} alt="Comuni" width={320} />
                        <Input
                            title="Insire seu email para redefinição de senha"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            tema="dark"
                        />
                        <div className="d-flex justify-content-between">
                            <Botao
                                title="Voltar"
                                onClick={() => history.push('/login')}
                                outline
                                tema="Secondary"
                            />
                            <Botao
                                title="Redefinir"
                                onClick={() => enviarCodigo()}
                                tema="Primary"
                                loading={load}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EsqueciSenha;