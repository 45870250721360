import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import Main from './pages/Main.routes';
import { GloblalStyle } from './style/GlobalStyle';
import Theme from './style/Theme';
function App() {
  return (
    <ThemeProvider theme={Theme}>
      <GloblalStyle />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <Main />
    </ThemeProvider>
  );
}

export default App;
