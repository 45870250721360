import React from 'react';
import { Switch, Route } from 'react-router';
import LP from './LP';
import Login from './Auth/Login/Login'
import Principal from './Main/Principal/Principal';
import Registrar from './Auth/Registrar/Registrar';
import EsqueciSenha from './Auth/EsqueciSenha/EsqueciSenha';
import RedefinirSenha from './Auth/EsqueciSenha/RedefinirSenha';
import Sidebar from '../components/Sidebar/Index';
import Grupos from '../pages/Main/Grupos/Grupos';
import Privacidade from '../pages/Privacidade';

const pages: React.FC = () => {
  return (
    <Switch>
      <Route path="/" component={LP} exact />
      <Route path="/login" component={Login} exact />
      <Route path="/registrar" component={Registrar} exact />
      <Route path="/esqueciSenha" component={EsqueciSenha} exact />
      <Route path="/redefinirSenha" component={RedefinirSenha} exact />
      <Route path="/Privacidade" component={Privacidade} exact />
      <Sidebar>
        <Route path="/principal" component={Principal} exact />
        <Route path="/grupos" component={Grupos} exact />
      </Sidebar>
    </Switch>
  );
}

export default pages;