import styled from 'styled-components';
import donut from '../../assets/img/donut.png';
import donut2 from '../../assets/img/donut_2.png';
export const LPContainer = styled.div`
    overflow: hidden;
    #Principal {
        background-image: url(${donut});
        background-repeat: no-repeat;
        background-position: 32vw 140% ;
        .evento {
            position: absolute;
            right: 240px;
            top: -72px ;
            @media(max-width: 762px) {
                right: 0;
                top: 0;
                position: relative;
            }
        }
    }


    #Agendamento {
        .img_agenda {
            width: 120px;
            height: 120px;
            box-shadow: 0px 0px 8px #00000014;
            border-radius: 20px;
            margin-right: 18px;
        }
        .img_big_agenda {
            width: 100%;
            height: 100%;
            background: #777;
            border-radius: 20px;
        }
    }

    
    .donut_2 {
        background: url(${donut2});
        background-position: 50% 10%;
        background-repeat: no-repeat;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background-size: contain;
        position: absolute;
        width: 100%;
        height: 1080px;
        z-index: -1;
    }

    #Grupo {
        padding-top: 300px;
        padding-bottom: 160px;
    }

    #Contato {
        margin-top: 200px;
        margin-bottom: 100px;
    }

    footer {
        background: ${p => p.theme.colors.dark};
        padding: 20px;
    }
`;
