import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';


axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.timeout = 180000;

const token = localStorage.getItem("@COMUNI_TOKEN");
axios.defaults.headers.common["Authentication"] = token ? token : "";
const BaseUrl = '/api/';

const cache = setupCache({
    maxAge: 60 * 60 * 1000,
    ignoreCache: true
})

const Api = axios.create({
    baseURL: BaseUrl,
    adapter: cache.adapter
})

export default Api;

export const baseUrl = BaseUrl;